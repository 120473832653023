import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {DateTime} from "luxon";

export default class HireIndividualEmployee {
    public employerId: number | null = null;

    public branchIds: Array<number> = [];

    public workPlacesId: Array<number> = [];

    public isRemote = true

    public payType: PayType | null = null;

    public payPeriodType: PayPeriodType | null = null;

    public rate: number | null = null;

    public employeeId: number | null = null;

    public employmentStart: Date | null = null;

    public oficcer = false

    public zoneId = DateTime.local().zoneName
}